<template>
  <div>
    <h1>Facturen automatisatie/genereren</h1>
    <div class="flex flex-col gap-2 mt-4 mb-8">
      <small>Enkel ritten voor financieel dagboek: <b>{{ FINANCIAL_JOURNALS[0].label }}</b></small>
      <small>Enkel ritten waarvoor <u>nog geen factuur bestaan</u> zullen worden gefactureerd</small>
    </div>

    <UISelectStatsFilter
      v-model:day="form.day"
      v-model:month="form.month"
      v-model:year="form.year"
      verberg-type
      min-year="2023"
    />

    <div class="flex flex-row gap-2">
      <button class="btn success inline-block mt-8" @click="onSubmit()">
        Genereren
      </button>
      <button class="btn info inline-block mt-8" @click="onSubmit({ controle: true })">
        Enkel controleren
      </button>
    </div>
    <button class="btn warning inline-block mt-8" @click="onSubmit({ force: true })">
      Genereer en skip controle
    </button>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'

import { FINANCIAL_JOURNALS } from '@/constants/OPTIONS'
import useApi from '@/hooks/useApi'
import useRedirectSafe from '@/hooks/useRedirectSafe'

const api = useApi()

const form = ref({
  day: null,
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
})

const onSubmit = data => {
  const params = {
    ...form.value,
    ...(data || {}),
    journal_id: 1,
  }

  api('FINANCIAL_DOCS_GENERATE_BY_DATE', params).then(() => {
    if (!data?.controle) {
      useRedirectSafe('/facturatie')
    }
  })
}
</script>
